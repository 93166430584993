<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="car-box">
        <detail-sanitaire
            :animal="animal"
            :create-by="createBy"
            :create-at="createAt"
        >
        <template slot="header">
            <h4>{{ subject }}</h4>
        </template>
        <template slot="content">
            <div class="row info">
                <div class="col-12">
                <h6> Date du rendez-vous </h6>
                <p>{{ apDate|capitalize }} {{ timeSlot }}</p>
                </div>
            </div>
            <div class="row info">
                <div class="col-12">
                <h6> Status </h6>
                <p>{{ status }}</p>
                </div>
            </div>
            <div class="row info">
                <div class="col-12">
                <h6> Evenement</h6>
                <p>{{ event }}</p>
                </div>
            </div>
            <div class="row info">
                <div class="col-12">
                <h6> Information Supplémentaire </h6>
                <p>{{ complement }}</p>
                </div>
            </div>
            <div class="row" style="margin-top: 50px" v-if="status === 'Pending'">
                <div class="col-12 text-center actions">
                    <a 
                        href="javascript:void(0)"
                        @click.prevent="confirm"
                        class="btn btn-success btn-lg"
                    >
                        Confirmer
                    </a>
                    <a 
                        href="javascript:void(0)"
                        @click.prevent="reject"
                        class="btn btn-dark btn-lg"
                    >
                        Rejetter
                    </a>
                </div>
            </div>
        </template>
        </detail-sanitaire>
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/navbar.vue';
import DetailSanitaire from '../../components/sanitaire/detailSanitaire.vue'
import { mapGetters } from 'vuex';
import moment from 'moment'
import { CONFIRM_APPOINTMENT } from '../../graphql/events';
export default {
    components: {Navbar, DetailSanitaire},
    data(){
        return {
            navbarItems: [
                {   
                    libelle: "Rendez-vous", 
                    route: 'rendez_vous'
                },
                {
                    libelle: 'Appointment',
                    route: 'rdv_appointment'
                },
                {
                    libelle: 'Detail'
                }
            ],
            pageIcon: 'la-calendar',
            pageTitle: 'Détail Appointment',
            pageDescription: 'Détail d\'une demande de rendez-vous client CASEAC',
            
        }
    },
    methods: {
        confirm(){
            this.$apollo.mutate({
                mutation: CONFIRM_APPOINTMENT,
                variables: {
                    "uid": this.appointment.uid
                },
                update: (data) => {
                    if(data) console.log(`Appointment ${this.appointment.uid} confirmed successfully`)
                    else console.log(`Error in confirming appointment ${this.appointment.uid}`)
                }
            }).then(() => {
                this.$router.push({name: 'rdv_appointment'})
            }).catch((error) => {
                console.error(error)
            })
        },
        reject(){

        }
    },
    computed: {
        ...mapGetters({
            appointments: 'events/appointments',
            motifs: 'sanitaire/motifConsultations',
            slots: 'events/timeSlots'
        }),
        timeSlot(){
            let s = this.slots.find(item => item.uid === this.appointment.timeslot)
            if(s) return s.startTime
            return '-'
        },
        apDate(){
            moment.locale('fr')
            return moment(this.appointment.date).format('dddd DD-MM-YYYY')
        },
        appointment(){
            return this.appointments.find(item => item.uid === this.$route.params.uid)
        },
        hasAppointment(){
            return this.appointment !== null && this.appointment !== undefined
        },
        animal(){
            if(this.hasAppointment && this.appointment.animal !== 'other') return this.appointment.animal
            return null
        },
        createBy(){
            return (this.hasAppointment)? this.appointment.user : null
        },
        createAt(){
            return (this.hasAppointment)? this.appointment.createdAt : null
        },
        subject(){
            if(this.hasAppointment){
                let s = JSON.parse(this.appointment.subject)
                if(s.other) return s.libelle
                let consultaiton = this.motifs.find(item => item.uid === s.libelle)
                if(consultaiton) return consultaiton.libelle
            }
            return '-'
        },
        status(){
            return (this.hasAppointment)? this.appointment.status : null
        },
        complement(){
            return (this.hasAppointment)? this.appointment.complement : null
        },
        event(){
            return (this.hasAppointment)? this.appointment.event : null
        }
    }
}
</script>